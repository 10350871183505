import { Workbox } from "workbox-window";
export const registerSW = async () => {
  if (
    typeof window !== "undefined" &&
    process.env.NODE_ENV !== "development" &&
    !/sw=false/gi.test(location.search)
  ) {
    if ("serviceWorker" in navigator) {
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

      const wb = new Workbox(swUrl);
      wb.addEventListener("activated", (event) => {
        // `event.isUpdate` will be true if another version of the service
        // worker was controlling the page when this version was registered.
        const urlsToCache = [
          location.href,
          ...performance.getEntriesByType("resource").map((r) => r.name)
        ];
        // Send that list of URLs to your router in the service worker.
        wb.messageSW({
          type: "CACHE_URLS",
          payload: { urlsToCache }
        });

        if (!event.isUpdate) {
          console.log("Service worker activated for the first time!");
          // If your service worker is configured to precache assets, those
          // assets should all be available now.
        }
      });

      wb.addEventListener("waiting", () => {
        console.log(
          `A new service worker has installed, but it can't activate` +
            `until all tabs running the current version have fully unloaded.`
        );
      });
      // Register the service worker after event listeners have been added.
      wb.register();
    }
  }
};
