import React, { ComponentType, lazy, Suspense } from "react";
import ReactDOM from "react-dom/client";
import { LoadingScreen } from "./components/LoadingScreen/LoadingScreen";
import { assets } from "./consts/assets";
import reportWebVitals from "./reportWebVitals";
import { isPwa } from "./utils/isPwa";
import { isPwaInstallable } from "./utils/isPwaInstallable";
import { registerSW } from "./serviceWorkerRegistration";

const rootElement = document.getElementById("root") as HTMLElement;
const root = ReactDOM.createRoot(rootElement);
const App = lazy(() => waitForAssets(import("./AppWrapper")));

function waitForAssets(promise: Promise<{ default: ComponentType<unknown> }>) {
  return new Promise((resolve) => {
    setTimeout(resolve, 1000);
  }).then(() =>
    import("./utils/loadAudio")
      .then(({ loadAudio }) => loadAudio(assets))

      .then(() => import("./utils/extractUserLangFromBrowser"))
      .then(({ extractUserLangFromBrowser }) => extractUserLangFromBrowser())
      .then(() => promise)
  );
}

if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
  window.document.addEventListener(
    "touchmove",
    (e) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (e.scale !== 1) {
        e.preventDefault();
      }
    },
    { passive: false }
  );
}

isPwaInstallable();

const isPwaMode = isPwa();

root.render(
  <React.StrictMode>
    <Suspense fallback={<LoadingScreen />}>
      <div style={{ height: isPwaMode ? "100vh" : "100%", position: "fixed", width: "100%" }}>
        <App />
      </div>
    </Suspense>

    <style jsx global>{`
      html,
      body,
      #root,
      .GameApp {
        height: 100%;
        width: 100%;
        margin: 0;
      }

      html {
        scroll-behavior: smooth;
      }
      body {
        font-family: sans-serif, Arial, Helvetica;
        overflow: hidden;
        touch-action: none;
        scroll-behavior: smooth;
        user-select: none;
        height: ${isPwaMode ? "100vh" : "100%"};
        width: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        -webkit-tap-highlight-color: transparent;
      }

      * {
        box-sizing: border-box;
        top: 0;
        left: 0;
        backface-visibility: hidden;
        transform: translate3d(0, 0, 0);
      }

      ul {
        list-style: none;
        border: 0;
        padding: 0;
        margin: 0;
      }

      svg {
        border: none;
        outline: none;
        -webkit-tap-highlight-color: transparent;
      }
      button {
        background: none;
        cursor: pointer;
        border: none;
        padding: 0;
        margin: 0;
        outline: none;
        -webkit-tap-highlight-color: transparent;
      }

      button:disabled {
        cursor: not-allowed;
      }

      /* Fade In */
      .fade-in {
        animation-duration: 0.3s;
        animation-delay: 0.2s;
        animation-name: animate-fade;
        animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
        animation-fill-mode: backwards;
      }

      @keyframes animate-fade {
        0% {
          opacity: 0;
          filter: brightness(0.8) saturate(0.8);
          transform: scale(0.8, 0.8);
        }
        100% {
          opacity: 1;
          filter: brightness(1) saturate(1);
          transform: scale(1, 1);
        }
      }

      /* Fade In */
      .fade-in-easy {
        animation-duration: 0.3s;
        animation-name: animate-fade-easy;
        animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
        animation-fill-mode: backwards;
      }

      @keyframes animate-fade-easy {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }

      .delay-1 {
        animation-delay: 0.2s;
      }
      .delay-2 {
        animation-delay: 0.3s;
      }
      .delay-3 {
        animation-delay: 0.4s;
      }
      .delay-4 {
        animation-delay: 0.5s;
      }
      .delay-5 {
        animation-delay: 0.6s;
      }
      .delay-6 {
        animation-delay: 0.7s;
      }
    `}</style>
  </React.StrictMode>
);

if (process.env.NODE_ENV === "development") {
  reportWebVitals(console.log);
}
registerSW();
