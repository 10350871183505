import { create } from "zustand";
import { GameConfigActions, GameConfigState, PWA_STATUS } from "./types";

// define the initial state
const initialState: GameConfigState = {
  isLoading: false,
  language: "",
  dict: null,
  lotteryWords: [],
  assets: {
    media: {}
  },
  audioContext: null,
  settings: { audio: true, userLogged: null, welcomeShown: false },
  pwa: { promptEvt: null, isInstalled: PWA_STATUS.UNKNOWN }
};
export const useGameConfig = create<GameConfigState & GameConfigActions>((set) => ({
  ...initialState,
  initConfig: ({ language, dict, lotteryWords }) => set({ language, dict, lotteryWords }),
  setLoading: (isLoading) => set({ isLoading }),
  setAssets: (assets) => set({ assets }),
  setPwaPrompt: (promptEvt) => set((prev) => ({ pwa: { ...prev.pwa, promptEvt } })),
  setPwaIsInstalled: (isInstalled) => set((prev) => ({ pwa: { ...prev.pwa, isInstalled } })),
  setAudioContext: (audioContext) => set({ audioContext }),
  audioToggle: () =>
    set((prev) => ({ settings: { ...prev.settings, audio: !prev.settings.audio } })),
  setUserLoggedFlag: (userLogged) =>
    set((prev) => ({ settings: { ...prev.settings, userLogged } })),
  setUserWelcomeShown: (welcomeShown) =>
    set((prev) => ({ settings: { ...prev.settings, welcomeShown } }))
}));
