export const isPwa = () => {
  const mqStandAlone = "(display-mode: standalone)";

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.navigator.standalone ||
    window.matchMedia(mqStandAlone).matches ||
    document?.location?.search.match(/^(.*?(\bpwa\b)[^$]*)$/gi) !== null
  );
};
