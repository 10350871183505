export const assets = {
  media: [
    "background.mp3",
    "end_timer.mp3",
    "timer_pass.mp3",
    "losttime.mp3",
    "bonus.mp3",
    "roll.mp3",
    "error.mp3",
    "exist.mp3",
    "word_found.mp3",
    "c1.mp3",
    "c2.mp3",
    "c3.mp3",
    "c4.mp3",
    "click.mp3",
    "change_word.mp3",
    "next_word_enabled.mp3"
  ]
};
