import { PWA_STATUS } from "../states/useMatchState/types";
import { useGameConfig } from "../states/useMatchState/useGameConfig";
import { isPwa } from "./isPwa";

export function isPwaInstallable() {
  const isPwaMode = isPwa();
  if (isPwaMode) return;

  window.addEventListener("beforeinstallprompt", (e) => {
    // Prevents the default mini-infobar or install dialog from appearing on mobile
    e.preventDefault();
    // Save the event because you'll need to trigger it later.
    useGameConfig.getState().setPwaIsInstalled(PWA_STATUS.INSTALLABLE);
    useGameConfig.getState().setPwaPrompt(e as unknown as { prompt: () => void });
  });
  window.addEventListener("appinstalled", () => {
    useGameConfig.getState().setPwaIsInstalled(PWA_STATUS.INSTALLED);
  });
}
